/*----------------genealogy-scroll----------*/
.genealogy-scroll {
  /* overflow: auto; */
  /* max-width: 100%; */
  white-space: nowrap;
}

.genealogy-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.genealogy-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}

.genealogy-scroll::-webkit-scrollbar-thumb {
  background: #888;
  transition: 0.5s;
}

.genealogy-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  transition: 0.5s;
}

/*----------------genealogy-tree----------*/
.genealogy-body {
  overflow: auto; /* Allow overflow to make the scrollbars appear */
  text-align: center;
  padding: 50px 0;
  position: relative;
}

.genealogy-tree {
  display: inline-block;
  min-width: 100%; /* Ensure the tree expands to fit the available width */
  white-space: nowrap; /* Prevent the tree from wrapping */
}

.genealogy-tree ul {
  padding-top: 20px;
  position: relative;
  padding-left: 0px;
  display: flex;
  justify-content: center;
}

.genealogy-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
}

.genealogy-tree li::before,
.genealogy-tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #ccc;
  width: 50%;
  height: 20px;
}

.genealogy-tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #ccc;
}

.genealogy-tree li:only-child::after,
.genealogy-tree li:only-child::before {
  display: none;
}

.genealogy-tree li:only-child {
  padding-top: 0;
}

.genealogy-tree li:first-child::before,
.genealogy-tree li:last-child::after {
  border: 0 none;
}

.genealogy-tree li:last-child::before {
  border-right: 2px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.genealogy-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.genealogy-tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 20px;
}

/*--------------member-card-design----------*/
.member-view-box {
  padding: 0px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}

.member-image img {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: #000;
  z-index: 1;
}

.member-details {
  margin-top: 10px;
}

.member-details p {
  font-size: 14px;
  margin: 0;
}

.copy-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
